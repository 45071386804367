import { useQueryClient } from '@tanstack/react-query';
import { Translate } from 'next-translate';
import useTranslation from 'next-translate/useTranslation';
import Head from 'next/head';
import { useMemo } from 'react';

import { Translate as TranslateLocale } from '@core/constant';
import { useContextRouting, useContextUtil } from '@core/context';
import { getStaticPathAsset, getStaticPathPng } from '@core/media';
import {
  BreadcrumbObject,
  ELanguage,
  ELanguageTags,
  ERouting,
  NewsArticleLdProps,
  ProductLdProps,
} from '@core/type';
import { EQueryKey, PageRatingResponse } from '@core/type/api';
import {
  getImage,
  getLanguageFromTags,
  getTranslatedRoute,
  getTypeRoute,
  handleDynamicUrl,
} from '@core/util';

import { ogImages, pathTranslations } from '../constants';
import { getEnvironmentPublic } from '../helpers/environment';
import { getFullUrl } from '../helpers/routing';
import { OgImageRouterType } from '../interfaces';
import { MetaTagProps, SchemaObject } from './interface-layout';

const getIsoDate = (date: string) => new Date(date).toISOString().split('T')[0];

const getOrganisation = (host: string, cdn: string) => {
  return {
    '@context': 'http://schema.org',
    '@type': 'Organization',
    name: 'GOLD AVENUE',
    url: host,
    logo: getStaticPathAsset('favicon.png', cdn),
    contactPoint: [
      {
        '@type': 'ContactPoint',
        telephone: '‍+41225189212',
        contactType: 'customer service',
      },
    ],
  };
};

const getSearch = (host: string) => {
  return {
    '@context': 'http://schema.org',
    '@type': 'WebSite',
    name: 'GOLD AVENUE®',
    url: host,
    potentialAction: {
      '@type': 'SearchAction',
      target: `${host}/search?q={search_term_string}`,
      'query-input': 'required name=search_term_string',
    },
  };
};

const getItem = (position: number, url: string, name: string): SchemaObject => {
  return {
    '@type': 'ListItem',
    position: position,
    item: {
      '@id': url,
      name: name,
    },
  };
};

const getNewsArticle = (cdn: string, article: NewsArticleLdProps): SchemaObject => {
  return {
    '@context': 'https://schema.org',
    '@type': 'NewsArticle',
    headline: article.title,
    description: article.subtitle || article.rawContent,
    datePublished: article.publishedDate ? getIsoDate(article.publishedDate) : undefined,
    dateModified: article.modifiedDate ? getIsoDate(article.modifiedDate) : undefined,
    image: getImage(article.image),
    author: {
      '@type': 'Person',
      name: 'Goldavenue SA',
    },
    publisher: {
      '@type': 'Organization',
      name: 'Goldavenue SA',
      logo: {
        '@type': 'ImageObject',
        url: getStaticPathAsset('favicon.png', cdn),
      },
    },
  };
};

const getProduct = (url: string, product: ProductLdProps): SchemaObject => {
  return {
    '@context': 'http://schema.org',
    '@type': 'Product',
    name: product.name,
    sku: product.specificities.sku,
    mpn: product.specificities.sku,
    brand: 'Gold Avenue',
    image: getImage(product.image),
    description: product.rawDescription,
    offers: {
      '@type': 'Offer',
      availability: 'http://schema.org/InStock',
      price: product.totalPrice,
      priceValidUntil: `${new Date().toISOString().split('T')[0]}`,
      priceCurrency: product.currencyIso,
      url: url,
    },
  };
};

const getPageRating = (title: string, pageRating: number): SchemaObject => {
  return {
    '@context': 'http://schema.org',
    '@type': 'Rating',
    name: title,
    ratingValue: pageRating,
  };
};

const getScript = (key: string, schemaObject: SchemaObject, nonce?: string, enable?: boolean) =>
  enable && (
    <script
      key={key}
      nonce={nonce}
      type="application/ld+json"
      dangerouslySetInnerHTML={{
        __html: JSON.stringify(schemaObject),
      }}
    />
  );

const getBreadcrumb = (
  host: string,
  breadcrumbObjects: BreadcrumbObject[],
  t: Translate,
  language: ELanguageTags,
) => {
  if (!breadcrumbObjects) {
    return {};
  }

  const itemListElement = breadcrumbObjects.map(
    ({ keyRoute, href, title, query: queryParams }, index) => {
      return getItem(
        index + 1,
        href ? `${host}${href}` : getFullUrl(host, language, keyRoute, queryParams),
        title || t(keyRoute),
      );
    },
  );

  return {
    '@context': 'http://schema.org/',
    '@type': 'BreadcrumbList',
    itemListElement,
  };
};

export const MetaTag = ({
  metas,
  breadcrumbObjects,
  keyRoute,
  language,
  currency,
  price,
  alternates,
  article,
  product,
  cmsPageId,
}: MetaTagProps) => {
  const { t: tBreadcrumb } = useTranslation(TranslateLocale.common.BREADCRUMB);
  const { t: tNotFound } = useTranslation(TranslateLocale.page.NOT_FOUND);
  const { t: tCommon } = useTranslation(TranslateLocale.common.COMMON);
  const { query } = useContextRouting();
  const { nonce } = useContextUtil();
  const { t } = useTranslation(getTypeRoute(keyRoute).translation);
  const { host, cdn } = getEnvironmentPublic();
  const queryClient = useQueryClient();

  const isRouteHome = keyRoute === ERouting.HOME;
  const isRouteNewsArticle =
    keyRoute === ERouting.BLOG_ARTICLE ||
    keyRoute === ERouting.PRECIOUS_METAL_GUIDE ||
    keyRoute === ERouting.PRECIOUS_METAL_GUIDE_LEVEL1 ||
    keyRoute === ERouting.PRECIOUS_METAL_GUIDE_LEVEL2 ||
    keyRoute === ERouting.PRECIOUS_METAL_GUIDE_LEVEL3;
  const isRouteProduct = keyRoute === ERouting.PRODUCT_SHOW;
  const ogImageProps = ogImages[language];
  const ogImageFileName =
    ogImageProps[keyRoute as keyof OgImageRouterType] ?? ogImageProps[ERouting.HOME];
  const fullUrl = getFullUrl(host, language, keyRoute, query);

  const getRoutes = () => {
    const lng = getLanguageFromTags(language);
    const alternate = alternates?.find(({ language: lng }) => lng === ELanguageTags.EN_US);
    const { srsltid, ...alternateQuery } = alternate?.query || {};

    const defaultLngUrl = isRouteHome
      ? host
      : alternate
        ? getFullUrl(host, ELanguageTags.EN_US, keyRoute, alternateQuery)
        : null;

    const alternateLinks = alternates
      ?.filter(({ language: lng }) => lng !== ELanguageTags.EN_US)
      // We don't show default alternates in the header for SEO reasons
      .filter(({ keyRoute: alternateKey }) => keyRoute === alternateKey)
      .map(({ language: lng, keyRoute, query }) => {
        const { srsltid, ...queryAlternate } = query || {};

        return (
          <link
            rel="alternate"
            key={lng}
            hrefLang={getLanguageFromTags(lng)}
            href={getFullUrl(host, lng, keyRoute, queryAlternate)}
          />
        );
      });

    const canonicalLink =
      isRouteHome && lng === ELanguage.EN
        ? host
        : `${
            handleDynamicUrl(
              `${host}/${lng}${getTranslatedRoute(
                keyRoute,
                language as Exclude<ELanguageTags, ELanguageTags.DEFAULT>,
                pathTranslations,
              )}`,
              query,
            ).route
          }${+query?.page > 1 ? `?page=${query.page as string}` : ''}`;

    const defaultLngLinks = [];
    if (defaultLngUrl) {
      defaultLngLinks.push(
        <link rel={'alternate'} key={lng} hrefLang={ELanguage.EN} href={defaultLngUrl} />,
        <link rel={'alternate'} key={'x-default'} hrefLang={'x-default'} href={defaultLngUrl} />,
      );
    }

    return [
      ...alternateLinks,
      ...defaultLngLinks,
      <link rel="canonical" key={`canonical/${lng}`} href={canonicalLink} />,
    ];
  };

  const metaMemo = useMemo(() => {
    const translation = getTypeRoute(keyRoute).translation;
    const translationTitle = translation ? t('titleMetaTag') : tNotFound('titleMetaTag');
    const title =
      metas?.title ||
      (query?.page && Number(query?.page) > 1
        ? `${translationTitle} (${tBreadcrumb('page')} ${String(query.page)})`
        : translationTitle);

    const ogTitle = metas?.og?.title || title;

    const translationDescription = translation
      ? t('descriptioneMetaTag')
      : tNotFound('descriptioneMetaTag');
    const rawDescription = metas?.description || translationDescription;
    const rawOgDescription = metas?.og?.description || rawDescription;

    const description =
      query?.page && Number(query?.page) > 1
        ? `${tBreadcrumb('page')} ${String(query.page)}: ${rawDescription}`
        : rawDescription;
    const ogDescription =
      query?.page && Number(query?.page) > 1
        ? `${tBreadcrumb('page')} ${String(query.page)}: ${rawOgDescription}`
        : rawOgDescription;

    return (
      <>
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta property="og:description" content={ogDescription} />
        <meta property="og:image:height" content={metas?.og?.imageHeight?.toString() || '627'} />
        <meta property="og:image:width" content={metas?.og?.imageWidth?.toString() || '1170'} />
        <meta property="og:title" content={ogTitle} />
        <meta property="og:site_name" content={tCommon('siteNameMetaTag')} />

        {keyRoute === ERouting.PRODUCT_SHOW && (
          <>
            <meta property="og:type" content="product" />
            <meta property="og:price:amount" content={price?.toString()} />
            <meta property="og:price:currency" content={currency} />
            <meta
              name="page-type"
              content="product"
              data-locale={getLanguageFromTags(language)}
              data-page-name={title}
              data-platform={getLanguageFromTags(language)}
            />
            {query?.currency_iso && <meta name="robots" content="noindex" />}
          </>
        )}
      </>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [keyRoute, metas, language, query?.page]);

  const metaLanguage = useMemo(
    () => <meta name="locale" content={getLanguageFromTags(language)} />,
    [language],
  );

  const organisationLdJson = useMemo(
    () => getScript('organisation-ld-json', getOrganisation(host, cdn), nonce, isRouteHome),
    [cdn, host, isRouteHome],
  );

  const searchLdJson = useMemo(
    () => getScript('search-ld-json', getSearch(host), nonce, isRouteHome),
    [cdn, host, isRouteHome],
  );

  const pageRatingLdJson = useMemo(() => {
    if (!cmsPageId) {
      return null;
    }

    const data = queryClient.getQueryData<PageRatingResponse>([
      EQueryKey.WIDGET_PAGE_RATING,
      language,
      cmsPageId,
    ]);

    if (!data?.rating) {
      return null;
    }

    const translation = getTypeRoute(keyRoute).translation;
    const translationTitle = translation ? t('titleMetaTag') : tNotFound('titleMetaTag');
    const title =
      metas?.title ||
      (query?.page && Number(query?.page) > 1
        ? `${translationTitle} (${tBreadcrumb('page')} ${String(query.page)})`
        : translationTitle);

    return getScript(
      'rating-ld-json',
      getPageRating(title, data.rating),
      nonce,
      Boolean(data.rating),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nonce, query?.page, cmsPageId]);

  const breadcrumbLdJson = useMemo(
    () =>
      getScript(
        'breadcrumb-ld-json',
        getBreadcrumb(host, breadcrumbObjects, tBreadcrumb, language),
        nonce,
        !!getTypeRoute(keyRoute).breadcrumb,
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [breadcrumbObjects, host, keyRoute, language, query, tBreadcrumb],
  );

  const articleLdJson = useMemo(() => {
    if (article) {
      return getScript(
        'newsArticle-ld-json',
        getNewsArticle(cdn, article),
        nonce,
        isRouteNewsArticle && !!article,
      );
    }
    return null;
  }, [cdn, article, isRouteNewsArticle]);

  const productLdJson = useMemo(() => {
    if (product) {
      return getScript(
        'product-ld-json',
        getProduct(fullUrl, product),
        nonce,
        isRouteProduct && !!product,
      );
    }
    return null;
  }, [fullUrl, currency, language, product, isRouteProduct]);

  return (
    <Head>
      {metaLanguage}
      {metaMemo}
      <meta property="og:url" content={fullUrl} />
      <meta
        property="og:image"
        content={metas?.og?.imageUrl || getStaticPathPng(ogImageFileName, cdn)}
      />
      {getRoutes()}
      {organisationLdJson}
      {searchLdJson}
      {breadcrumbLdJson}
      {articleLdJson}
      {productLdJson}
      {pageRatingLdJson}
    </Head>
  );
};
