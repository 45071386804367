import withTranslation from 'next-translate/withTranslation';
import { useMemo } from 'react';

import { DrawerLine, Line, Typography } from '@core/component';
import { EDrawerLevel } from '@core/component/interface';
import { Translate, metalPriceToSlug, metalToSlug, referralGaUrl } from '@core/constant';
import {
  useContextAuth,
  useContextCurrency,
  useContextProfile,
  useContextUser,
} from '@core/context';
import { useLanguage } from '@core/hook';
import { EColor, EMetal, ERouting, ETypographyVariant, WithTranslation } from '@core/type';

import { CurrencyDrawerLine } from './CurrencyDrawerLine';
import { LanguageDrawerLine } from './LanguageDrawerLine';
import { SideNavDefaultProps } from './interface-header';

const TRANSLATIONS = {
  [EMetal.GOLD]: 'price.gold',
  [EMetal.SILVER]: 'price.silver',
  [EMetal.PLATINUM]: 'price.platinum',
  [EMetal.PALLADIUM]: 'price.palladium',
};

const SideNavDefaultContainer = ({
  i18n: { t },
  shouldShowCurrencyLink,
  closeDrawer,
  subcategories,
  drawerLevelProps,
  handleDrawerLevelProps,
}: WithTranslation & SideNavDefaultProps) => {
  const { logout } = useContextAuth();
  const { isLogged, isReady } = useContextUser();
  const { currency } = useContextCurrency();
  const { hasNotCompletedStep, hasStorageFeesUnpaid } = useContextProfile();

  const { language } = useLanguage();

  const memoMenuLinkLogged = useMemo(() => {
    if (isReady && isLogged) {
      return (
        <>
          <Line margin="16px 16px 16px 40px" />
          <DrawerLine
            route={ERouting.WALLET}
            title={t('menu.wallet')}
            drawerLevelProps={drawerLevelProps}
            handleDrawerLevelProps={closeDrawer}
          />
          <DrawerLine
            route={ERouting.WALLET_AUTO_SAVINGS}
            title={t('menu.autoSavings')}
            drawerLevelProps={drawerLevelProps}
            handleDrawerLevelProps={closeDrawer}
          />
          <DrawerLine
            title={t('menu.storageFees')}
            drawerLevelProps={drawerLevelProps}
            handleDrawerLevelProps={closeDrawer}
            isInvalid={hasStorageFeesUnpaid}
            route={ERouting.STORAGE_FEES}
          />
          <DrawerLine
            title={t('menu.paymentMethods')}
            drawerLevelProps={drawerLevelProps}
            handleDrawerLevelProps={closeDrawer}
            route={ERouting.PAYMENTS}
          />
          <DrawerLine
            route={ERouting.WALLET_ORDERS}
            title={t('menu.orderHistory')}
            drawerLevelProps={drawerLevelProps}
            handleDrawerLevelProps={closeDrawer}
          />
          <DrawerLine
            route={ERouting.ACCOUNT_TRANSACTIONS}
            title={t('menu.transactionHistory')}
            drawerLevelProps={drawerLevelProps}
            handleDrawerLevelProps={closeDrawer}
          />
          <DrawerLine
            title={t('menu.alertsSettings')}
            route={ERouting.ALERT_SETTINGS}
            drawerLevelProps={drawerLevelProps}
            handleDrawerLevelProps={closeDrawer}
          />
          <DrawerLine
            title={t('menu.wishList')}
            route={ERouting.WISHLIST}
            drawerLevelProps={drawerLevelProps}
            handleDrawerLevelProps={closeDrawer}
          />
          <DrawerLine
            title={t('menu.purchasingLimit')}
            route={ERouting.PURCHASING_LIMIT}
            drawerLevelProps={drawerLevelProps}
            handleDrawerLevelProps={closeDrawer}
            isInvalid={hasNotCompletedStep}
          />
          <DrawerLine
            title={t('menu.profileSettings')}
            route={ERouting.PROFILE}
            drawerLevelProps={drawerLevelProps}
            handleDrawerLevelProps={closeDrawer}
          />
        </>
      );
    }
    return null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isReady, isLogged, t, hasNotCompletedStep, closeDrawer]);

  const memoLogout = useMemo(() => {
    if (isReady && isLogged) {
      return (
        <>
          <Line margin="16px 16px 16px 40px" />
          <DrawerLine
            title={t('menu.signOut')}
            drawerLevelProps={drawerLevelProps}
            handleDrawerLevelProps={() => {
              closeDrawer();
              void logout();
            }}
          />
        </>
      );
    }
    return null;
  }, [isReady, isLogged, logout, t]);

  return (
    <>
      <DrawerLine
        drawerLevel={EDrawerLevel.SHOP}
        drawerLevelProps={drawerLevelProps}
        handleDrawerLevelProps={handleDrawerLevelProps}
        title={t('menu.shop')}
      >
        <DrawerLine
          route={ERouting.HOME_BUY}
          title={
            <Typography color={EColor.ACCENT} variant={ETypographyVariant.H6}>
              {t('menu.allProducts')}
            </Typography>
          }
          drawerLevelProps={drawerLevelProps}
          handleDrawerLevelProps={closeDrawer}
        />

        {Object.values(EMetal).map((metalIso) => {
          const metalName = t(TRANSLATIONS[metalIso]);

          return (
            <DrawerLine
              key={metalIso}
              title={metalName}
              drawerLevel={`${EDrawerLevel.SHOP}/${metalIso}`}
              drawerLevelProps={drawerLevelProps}
              handleDrawerLevelProps={handleDrawerLevelProps}
              {...(subcategories[metalIso]?.length > 0
                ? null
                : {
                    route: ERouting.BUY,
                    query: { metal: metalToSlug[language][metalIso] },
                    handleDrawerLevelProps: closeDrawer,
                  })}
            >
              {subcategories[metalIso]?.length > 0 && (
                <>
                  <DrawerLine
                    drawerLevel={`${EDrawerLevel.SHOP}/${metalIso}`}
                    route={ERouting.BUY}
                    query={{ metal: metalToSlug[language][metalIso] }}
                    title={
                      <Typography color={EColor.ACCENT} variant={ETypographyVariant.H6}>
                        {t('metalProducts', { metal: metalName })}
                      </Typography>
                    }
                    drawerLevelProps={drawerLevelProps}
                    handleDrawerLevelProps={closeDrawer}
                  />
                  {subcategories[metalIso].map(({ menuTitle, slug, children }) => (
                    <DrawerLine
                      key={slug}
                      title={menuTitle}
                      drawerLevel={`${EDrawerLevel.SHOP}/${metalIso}}/${slug}`}
                      drawerLevelProps={drawerLevelProps}
                      handleDrawerLevelProps={handleDrawerLevelProps}
                      {...(children?.length > 0
                        ? null
                        : {
                            route: ERouting.SHOP_SUBCATEGORY,
                            query: { metal: metalToSlug[language][metalIso], slug },
                            handleDrawerLevelProps: closeDrawer,
                          })}
                    >
                      {children?.length > 0 && (
                        <>
                          <DrawerLine
                            key={slug}
                            drawerLevel={`${EDrawerLevel.SHOP}/${metalIso}/${slug}`}
                            route={ERouting.SHOP_SUBCATEGORY}
                            query={{ metal: metalToSlug[language][metalIso], slug }}
                            title={
                              <Typography color={EColor.ACCENT} variant={ETypographyVariant.H6}>
                                {menuTitle}
                              </Typography>
                            }
                            drawerLevelProps={drawerLevelProps}
                            handleDrawerLevelProps={closeDrawer}
                          />
                          {children.map(({ menuTitle, slug: subSlug }) => (
                            <DrawerLine
                              key={subSlug}
                              drawerLevel={`${EDrawerLevel.SHOP}/${metalIso}/${slug}/${subSlug}`}
                              route={ERouting.SHOP_SUBCATEGORY}
                              query={{
                                metal: metalToSlug[language][metalIso],
                                slug: subSlug,
                              }}
                              title={menuTitle}
                              drawerLevelProps={drawerLevelProps}
                              handleDrawerLevelProps={closeDrawer}
                            />
                          ))}
                        </>
                      )}
                    </DrawerLine>
                  ))}
                </>
              )}
            </DrawerLine>
          );
        })}
      </DrawerLine>
      <DrawerLine
        drawerLevel={EDrawerLevel.CHARTS}
        drawerLevelProps={drawerLevelProps}
        handleDrawerLevelProps={handleDrawerLevelProps}
        title={t('menu.liveCharts')}
      >
        <DrawerLine
          title={`${t('menu.gold')} ${t('menu.charts')}`}
          drawerLevelProps={drawerLevelProps}
          route={ERouting.CHARTS}
          query={{
            metalSlug: metalPriceToSlug[language][EMetal.GOLD],
            slug: [currency.toLowerCase()],
          }}
          handleDrawerLevelProps={closeDrawer}
        />
        <DrawerLine
          title={`${t('menu.silver')} ${t('menu.charts')}`}
          route={ERouting.CHARTS}
          query={{
            metalSlug: metalPriceToSlug[language][EMetal.SILVER],
            slug: [currency.toLowerCase()],
          }}
          drawerLevelProps={drawerLevelProps}
          handleDrawerLevelProps={closeDrawer}
        />
        <DrawerLine
          title={`${t('menu.platinium')} ${t('menu.charts')}`}
          drawerLevelProps={drawerLevelProps}
          route={ERouting.CHARTS}
          query={{
            metalSlug: metalPriceToSlug[language][EMetal.PLATINUM],
            slug: [currency.toLowerCase()],
          }}
          handleDrawerLevelProps={closeDrawer}
        />
        <DrawerLine
          title={`${t('menu.palladium')} ${t('menu.charts')}`}
          drawerLevelProps={drawerLevelProps}
          route={ERouting.CHARTS}
          query={{
            metalSlug: metalPriceToSlug[language][EMetal.PALLADIUM],
            slug: [currency.toLowerCase()],
          }}
          handleDrawerLevelProps={closeDrawer}
        />
      </DrawerLine>
      <DrawerLine
        title={t('menu.storageSolution')}
        route={ERouting.STORAGE_SOLUTION}
        drawerLevelProps={drawerLevelProps}
        handleDrawerLevelProps={closeDrawer}
      />
      <DrawerLine
        title={t('menu.pricing')}
        route={ERouting.PRICING}
        drawerLevelProps={drawerLevelProps}
        handleDrawerLevelProps={closeDrawer}
      />
      <Line margin="16px 16px 16px 40px" />
      <DrawerLine
        title={t('menu.blog')}
        route={ERouting.BLOG_HOME}
        drawerLevelProps={drawerLevelProps}
        handleDrawerLevelProps={closeDrawer}
      />
      <DrawerLine
        title={t('menu.preciousMetalGuide')}
        route={ERouting.PRECIOUS_METAL_GUIDE}
        drawerLevelProps={drawerLevelProps}
        handleDrawerLevelProps={closeDrawer}
      />
      {memoMenuLinkLogged}
      <Line margin="16px 16px 16px 40px" />
      {isLogged ? (
        <DrawerLine
          title={t('menu.referFriend')}
          route={ERouting.REFERRALS}
          drawerLevelProps={drawerLevelProps}
          handleDrawerLevelProps={closeDrawer}
        />
      ) : (
        <DrawerLine
          title={t('menu.referFriend')}
          href={referralGaUrl[language]}
          target={'_blank'}
          drawerLevelProps={drawerLevelProps}
          handleDrawerLevelProps={closeDrawer}
        />
      )}
      <Line margin="16px 16px 16px 40px" />
      <DrawerLine
        route={ERouting.ABOUT_US}
        title={t('menu.aboutUs')}
        drawerLevelProps={drawerLevelProps}
        handleDrawerLevelProps={closeDrawer}
      />
      <DrawerLine
        route={ERouting.CONTACT}
        title={t('menu.contactUs')}
        drawerLevelProps={drawerLevelProps}
        handleDrawerLevelProps={closeDrawer}
      />
      <Line margin="16px 16px 16px 40px" />
      <CurrencyDrawerLine
        drawerLevelProps={drawerLevelProps}
        handleDrawerLevelProps={handleDrawerLevelProps}
        shouldShowCurrencyLink={shouldShowCurrencyLink}
      />
      <LanguageDrawerLine
        drawerLevelProps={drawerLevelProps}
        handleDrawerLevelProps={handleDrawerLevelProps}
      />
      {memoLogout}
    </>
  );
};

SideNavDefaultContainer.displayName = 'SideNavDefault';

// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
export const SideNavDefault = withTranslation(SideNavDefaultContainer, Translate.layout.HEADER);
