import type { AxiosInstance } from 'axios';

import { slugToMetal } from '@core/constant';
import { ECountry, ELanguageTags, EMetalSlug } from '@core/type';
import {
  AutoSavingsPageEntity,
  BlogHomePageEntity,
  EFAQCategory,
  FAQCategoryEntity,
  ForecastedDeliveryRequestEntity,
  ProductBrandFilterEntity,
  ProductCollectionFilterEntity,
  ProductPriceFilterEntity,
  ProductSelectionFilterEntity,
  ProductShopEntities,
  ProductTypeFilterEntity,
  ProductWeightFilterEntity,
  ShopPageEntity,
  SilverLandingPageEntity,
  SubcategoryShopPageEntity,
} from '@core/type/api';

import { BlogCategoriesService } from './blog-categories';
import { CategoriesService } from './categories';
import { ContentService } from './content';
import { DeliveryCountriesService } from './delivery-countries';
import { FAQService } from './faq';
import { ForecastedDeliveryService } from './forecasted-delivery';
import { HighlightedArticleService } from './highlighted-article';
import { HomepageArticlesService } from './homepage-articles';
import { PreciousMetalArticlesService } from './preciousMetalArticles';
import { ProductService } from './product';
import { ShopService } from './shop';
import { TrustpilotService } from './trustpilot';

const getShopPage = async (
  axiosInstance: AxiosInstance,
  {
    metal,
    locale,
  }: {
    metal?: EMetalSlug;
    locale: ELanguageTags;
  },
): Promise<ShopPageEntity> => {
  const promises: [
    Promise<ProductShopEntities>,
    Promise<ProductPriceFilterEntity>,
    Promise<ProductTypeFilterEntity>,
    Promise<ProductWeightFilterEntity>,
    Promise<ProductCollectionFilterEntity>,
    Promise<ProductBrandFilterEntity>,
    Promise<ProductSelectionFilterEntity>,
  ] = [
    ShopService.getProducts(axiosInstance, locale),
    ShopService.getFilterPrice(axiosInstance, locale),
    ShopService.getFilterProductType(axiosInstance, locale),
    ShopService.getFilterWeight(axiosInstance, locale),
    ShopService.getFilterCollections(axiosInstance, locale),
    ShopService.getFilterBrands(axiosInstance, locale),
    ShopService.getFilterSelection(axiosInstance, locale),
  ];
  if (metal) {
    const [
      products,
      filterPrice,
      filterProductType,
      filterWeight,
      filterCollection,
      filterBrand,
      filterSelection,
      category,
    ] = await Promise.all([
      ...promises,
      CategoriesService.getCategory(axiosInstance, metal, locale),
    ]);

    return {
      products: products.products,
      filter: {
        price: filterPrice.priceRanges,
        filterProductType: filterProductType.filterProductTypes,
        weight: filterWeight.weightRanges,
        collection: filterCollection.collections,
        brand: filterBrand.brands,
        selection: filterSelection.selections,
      },
      categories: CategoriesService.getCategories(locale).categories,
      metal: slugToMetal[locale][metal],
      title: category.title,
      text: category.description,
      metas: category.metas,
    };
  } else {
    const [
      products,
      filterPrice,
      filterProductType,
      filterWeight,
      filterCollection,
      filterBrand,
      filterSelection,
    ] = await Promise.all(promises);
    return {
      products: products.products,
      filter: {
        price: filterPrice.priceRanges,
        filterProductType: filterProductType.filterProductTypes,
        weight: filterWeight.weightRanges,
        collection: filterCollection.collections,
        brand: filterBrand.brands,
        selection: filterSelection.selections,
      },
      categories: CategoriesService.getCategories(locale).categories,
    };
  }
};

const getSubCategoriesShopPage = async (
  axiosInstance: AxiosInstance,
  {
    metal,
    id,
    locale,
  }: {
    metal?: EMetalSlug;
    id?: string;
    locale: ELanguageTags;
  },
): Promise<SubcategoryShopPageEntity> => {
  const [
    products,
    subcategory,
    filterPrice,
    filterProductType,
    filterWeight,
    filterCollection,
    filterBrand,
    filterSelection,
    category,
  ] = await Promise.all([
    ShopService.getProducts(axiosInstance, locale),
    ShopService.getSubcategoriesProducts(axiosInstance, { id }, locale),
    ShopService.getFilterPrice(axiosInstance, locale),
    ShopService.getFilterProductType(axiosInstance, locale),
    ShopService.getFilterWeight(axiosInstance, locale),
    ShopService.getFilterCollections(axiosInstance, locale),
    ShopService.getFilterBrands(axiosInstance, locale),
    ShopService.getFilterSelection(axiosInstance, locale),
    CategoriesService.getCategory(axiosInstance, metal, locale),
  ]);

  return {
    products: products.products,
    filter: {
      price: filterPrice.priceRanges,
      filterProductType: filterProductType.filterProductTypes,
      weight: filterWeight.weightRanges,
      collection: filterCollection.collections,
      brand: filterBrand.brands,
      selection: filterSelection.selections,
    },
    selectedFilters: subcategory.filters,
    categories: CategoriesService.getCategories(locale).categories,
    metal: subcategory.metalIso,
    title: category.title,
    text: category.description,
    metas: subcategory.metas,
    subtitle: subcategory.subtitle,
    image: subcategory.image,
    breadcrumbs: subcategory.breadcrumbs,
    alternates: subcategory.alternates,
  };
};

const getSilverLandingPage = async (
  axiosInstance: AxiosInstance,
  language: ELanguageTags,
): Promise<SilverLandingPageEntity> => {
  const [product, totalUsers, faqQuestions] = await Promise.all([
    ProductService.getProductSilverLP(axiosInstance, language),
    TrustpilotService.getTotalUsers(axiosInstance, language),
    FAQService.getFAQCategory(axiosInstance, language, EFAQCategory.SILVER_LP),
  ]);
  return { product, totalUsers, faqQuestions };
};

const getFaqQuestions = async (
  axiosInstance: AxiosInstance,
  slug: EFAQCategory,
  language: ELanguageTags,
): Promise<{ faqQuestions?: FAQCategoryEntity }> => {
  const [faqQuestions] = await Promise.all([
    FAQService.getFAQCategory(axiosInstance, language, slug),
  ]);
  return { faqQuestions };
};

const getAutoSavingsPage = async (
  axiosInstance: AxiosInstance,
  language: ELanguageTags,
): Promise<AutoSavingsPageEntity> => {
  const [product, totalUsers, faqQuestions] = await Promise.all([
    ProductService.getAutoSavingsProducts(axiosInstance, language),
    TrustpilotService.getTotalUsers(axiosInstance, language),
    FAQService.getFAQCategory(axiosInstance, language, EFAQCategory.REPEAT_BUY),
  ]);
  return { product, totalUsers, faqQuestions };
};

const getBlogTag = async (
  axiosInstance: AxiosInstance,
  slug: string,
  page: number,
  perPage: number,
  language: ELanguageTags,
) => {
  const [content, blogCategoriesData] = await Promise.all([
    ContentService.getTagArticles(axiosInstance, language, slug, page, perPage),
    BlogCategoriesService.getBlogCategories(axiosInstance, language),
  ]);

  const blogCategories = blogCategoriesData.blogCategories.sort((a, b) => a.position - b.position);

  return {
    ...content,
    blogCategories,
  };
};

const getBlogSearch = async (
  axiosInstance: AxiosInstance,
  slug: string,
  page: number,
  perPage: number,
  language: ELanguageTags,
) => {
  const [content, blogCategoriesData] = await Promise.all([
    ContentService.searchArticles(axiosInstance, language, slug, page, perPage),
    BlogCategoriesService.getBlogCategories(axiosInstance, language),
  ]);

  const blogCategories = blogCategoriesData.blogCategories.sort((a, b) => a.position - b.position);

  return {
    ...content,
    blogCategories,
  };
};

const getBlogCategory = async (
  axiosInstance: AxiosInstance,
  slug: string,
  page: number,
  perPage: number,
  language: ELanguageTags,
) => {
  const [content, blogCategoriesData] = await Promise.all([
    ContentService.getCategoryArticles(axiosInstance, language, slug, page, perPage),
    BlogCategoriesService.getBlogCategories(axiosInstance, language),
  ]);

  const blogCategories = blogCategoriesData.blogCategories.sort((a, b) => a.position - b.position);

  return {
    ...content,
    blogCategories,
  };
};

const getBlogArticlePage = async (
  axiosInstance: AxiosInstance,
  slug: string,
  language: ELanguageTags,
) => {
  const [content, blogCategoriesData] = await Promise.all([
    ContentService.blogBySlug(axiosInstance, language, slug),
    BlogCategoriesService.getBlogCategories(axiosInstance, language),
  ]);

  const blogCategories = blogCategoriesData.blogCategories.sort((a, b) => a.position - b.position);

  return {
    ...content,
    blogCategories,
  };
};

const getPreciousMetalArticleBySlug = async (
  axiosInstance: AxiosInstance,
  slug: string,
  language: ELanguageTags,
) => {
  const [contentArticle, contentArticles] = await Promise.all([
    PreciousMetalArticlesService.getPreciousMetalArticlesBySlug(axiosInstance, language, slug),
    PreciousMetalArticlesService.getPreciousMetalArticles(axiosInstance, language),
  ]);

  return {
    ...contentArticle,
    ...contentArticles,
  };
};

const getPreciousMetalGuideMainArticle = async (
  axiosInstance: AxiosInstance,
  language: ELanguageTags,
) => {
  const [contentArticle, contentArticles] = await Promise.all([
    PreciousMetalArticlesService.getPreciousMetalMainArticle(axiosInstance, language),
    PreciousMetalArticlesService.getPreciousMetalArticles(axiosInstance, language),
  ]);

  return {
    ...contentArticle,
    ...contentArticles,
  };
};

const getBlogHomePage = async (
  axiosInstance: AxiosInstance,
  language: ELanguageTags,
  page?: number,
  perPage?: number,
): Promise<BlogHomePageEntity> => {
  const [blog, articles, highlightedArticle, blogCategoriesData] = await Promise.all([
    ContentService.blog(axiosInstance, language, page, perPage),
    HomepageArticlesService.getHomepageArticles(axiosInstance, ELanguageTags.EN_US),
    HighlightedArticleService.getHighlightedArticle(axiosInstance, language),
    BlogCategoriesService.getBlogCategories(axiosInstance, language),
  ]);

  const blogCategories = blogCategoriesData.blogCategories.sort((a, b) => a.position - b.position);

  return { articles, ...blog, highlightedArticle, blogCategories };
};

const getProductPage = async (
  axiosInstance: AxiosInstance,
  params: ForecastedDeliveryRequestEntity & {
    slug: string;
  },
  language: ELanguageTags,
) => {
  const [product, deliveryCountriesData] = await Promise.all([
    ProductService.getProductBySlug(axiosInstance, language, params?.slug),
    DeliveryCountriesService.getDeliveryCountries(axiosInstance, language),
  ]);

  const forecastedDeliveryData = await ForecastedDeliveryService.getForecastedDelivery(
    axiosInstance,
    language,
    {
      existingProducts: [],
      newProduct: { id: product?.id, quantity: 1 },
      countryIso: ECountry.SWITZERLAND,
    } as ForecastedDeliveryRequestEntity,
  );

  return { ...product, ...deliveryCountriesData, forecastedDeliveryData };
};

export class PageService {
  public static readonly getShopPage = getShopPage;
  public static readonly getSubCategoriesShopPage = getSubCategoriesShopPage;
  public static readonly getBlogArticlePage = getBlogArticlePage;
  public static readonly getBlogHomePage = getBlogHomePage;
  public static readonly getBlogCategory = getBlogCategory;
  public static readonly getBlogTag = getBlogTag;
  public static readonly getBlogSearch = getBlogSearch;
  public static readonly getAutoSavingsPage = getAutoSavingsPage;
  public static readonly getSilverLandingPage = getSilverLandingPage;
  public static readonly getPreciousMetalArticleBySlug = getPreciousMetalArticleBySlug;
  public static readonly getPreciousMetalGuideMainArticle = getPreciousMetalGuideMainArticle;
  public static readonly getFaqQuestions = getFaqQuestions;
  public static readonly getProductPage = getProductPage;
}
