import withTranslation from 'next-translate/withTranslation';
import { memo, useMemo, useState } from 'react';

import {
  Box,
  DrawerSub,
  IconCountry,
  LinkTranslatedStyledAsLink,
  Typography,
} from '@core/component';
import { DrawerCloseProps, EDrawerLevel } from '@core/component/interface';
import { Translate } from '@core/constant';
import { useContextAlternate } from '@core/context';
import { useLanguage } from '@core/hook';
import { EActionAttribute, EColor, EPosition, LanguageEntity, WithTranslation } from '@core/type';
import { getCountryFromLanguage, getLanguageFromTags, getListLanguage } from '@core/util';

import { LanguageDrawerLineProps } from './interface-header';

const LanguageDrawerLineContainer = ({
  i18n: { t },
  position = EPosition.LEFT,
  drawerLevelProps,
  handleDrawerLevelProps,
  isBlogPage,
}: WithTranslation & DrawerCloseProps & LanguageDrawerLineProps) => {
  const { language } = useLanguage();
  const [languageList, setLanguageList] = useState<LanguageEntity[]>([]);

  const { alternates } = useContextAlternate();

  useMemo(() => {
    setLanguageList(getListLanguage(t));
  }, [t]);

  const languages = useMemo(
    () =>
      languageList.map(({ iso, name }: LanguageEntity) => {
        const alternate = alternates?.find(({ language }) => language === iso);

        return (
          <Box
            as={EActionAttribute.LI}
            marginBottom="16px"
            backgroundColor={iso === language ? EColor.SECONDARY : undefined}
            onClick={() => handleDrawerLevelProps({ [EDrawerLevel.LANGUAGE]: false })}
            key={iso}
          >
            <LinkTranslatedStyledAsLink
              language={iso}
              query={alternate?.query}
              keyRoute={alternate?.keyRoute}
              disableShallow
            >
              <Box display={'flex'} alignItems={'center'} padding={'8px 16px 8px 40px'}>
                <IconCountry country={getCountryFromLanguage(getLanguageFromTags(iso))} />
                <Box marginLeft={'8px'}>
                  <Typography whiteSpace={'nowrap'}>
                    {name} - {getLanguageFromTags(iso).toUpperCase()}
                  </Typography>
                </Box>
              </Box>
            </LinkTranslatedStyledAsLink>
          </Box>
        );
      }),
    [alternates, languageList],
  );

  return (
    <Box as={EActionAttribute.LI} padding="8px 16px 8px 40px">
      <DrawerSub
        isBlogPage={isBlogPage}
        drawerLevel={EDrawerLevel.LANGUAGE}
        drawerLevelProps={drawerLevelProps}
        handleDrawerLevelProps={handleDrawerLevelProps}
        position={position}
        title={t('language')}
        listTitle={
          <Box display={'flex'} alignItems={'center'}>
            <IconCountry country={getCountryFromLanguage(getLanguageFromTags(language))} />
            <Box marginLeft={'8px'}>
              <Typography whiteSpace={'nowrap'}>
                {languageList?.find((el) => el.iso === language)?.name} -{' '}
                {getLanguageFromTags(language).toUpperCase()}
              </Typography>
            </Box>
          </Box>
        }
      >
        {languages}
      </DrawerSub>
    </Box>
  );
};

export const LanguageDrawerLine = memo(
  withTranslation(LanguageDrawerLineContainer, Translate.common.LANGUAGE),
);
